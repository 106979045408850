/*!
* Version: 1.2.0
* Template: Example Project
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import "./bootstrap/functions";
// Variables
@import "./hope-ui-design-system/variable";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";

@import "./bootstrap/mixins";

// Hope Ui Design System Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

@import "./custom/auth/authentication";
@import "./custom/kanban/kanban";
@import "./custom/pricing/pricing";
@import "./custom/ui-kit/ui-kit";

// loader model
.loading-modal .modal-content {
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pageNumbers {
    justify-content: space-between;
    padding: 0 15px
}

.dataPerPage {
    width: fit-content
}

.profile-image {
    position: relative;
    width: fit-content;
    margin: auto;
}

.overlay-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    backdrop-filter: blur(10px);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay-image svg {
    height: 30px;
}

.overlay-image input {
    cursor: pointer;
    border-radius: 50%;
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.profile-image:hover .overlay-image {
    opacity: 1;
    transition: all 0.2s;
}

.profile-image img {
    border-radius: 50%;
    height: 130px;
    width: 130px;
}

.change-password {
    padding: 10% 20%;
}

.image-cropper {
    display: flex;
    gap: 25px;
    padding: 10px;
}

.btn-scroll {
    position: fixed;
    bottom: 20px;
    right: 20px;
    // margin:40px 0  auto;


}

.scrolling_pages {
    height: 85vh;
    overflow-y: auto;
}

/* Style for the buttons */
.custom-button {
    padding: 1px 2px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Style to add gap between buttons */
.button-container .custom-button+.custom-button {
    margin-left: 10px;
    /* Adjust this value to set the desired gap */
}

.button-container {
    text-align: end;
}

.card-body.plusSign {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.plus-icon {
    width: 50px;
    /* Set the desired width */
    height: 50px;
    color: #007bff;
    /* Set the desired height */
}

.service-ck {
    padding: 2%;
    width: 100%;
}

.tab-switch {
    position: relative;
    z-index: 1;
}

.card {
    width: 100%;
}

.custom-file-label::after {
    content: 'Browse';
}

.img-thumbnail {
    width: 100%;
}

//   .custom-file-input {
//     position: absolute;
//     bottom: 10px;
//     right: 10px;
//     width: 24px;
//     height: 24px;
//     cursor: pointer;
//     background-color: #fff;
//     border-radius: 50%;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//   }
// .card {
//     position: relative;
//     width: 200px; /* Adjust the card width as needed */
//     height: 200px; /* Adjust the card height as needed */
//     border: 1px solid #ccc;
//     padding: 10px;
//     margin: 10px;
//   }

.custom-file-input {
    width: 30px;
    position: absolute;
    right: 0;
    height: 40px;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
}

.custom-file {
    position: absolute;
    padding: 1px 2px;
    cursor: pointer;
    width: 100%;
}

.imageEditIcon {
    width: 15px;
    padding: 12px;
    position: absolute;
    right: 0;
    height: 15px;
    border-radius: 50%;
    background-color: #ffffff4d;
    color: black;
    backdrop-filter: blur(5px);
    margin: 2%;
}